import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Flex, Box, Text } from "rebass"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Title from "../components/title"
import Enumeration from "../components/enumeration"

const items = [
  {
    title: 'Akupunktur',
    id: 'akupunktur'
  },
  {
    title: 'Moxibustion & Schröpfen',
    id: 'moxibustion'
  },
  {
    title: 'Ernährungsberatung nach TCM',
    id: 'ernaehrungsberatung'
  },
  {
    title: 'Diagnostik',
    id: 'diagnostik'
  },
];

const ContentSection = ({ id, title, image, children }) => (
  <Box id={id} py={[4, 4]}>
    <Text
      color="#712911"
      fontSize={[ 5, 4 ]}>
      {title}
    </Text>
    <Flex flexWrap='wrap' mx={-3} my={[0, 4]}>
      <Box px={3} py={[4, 0]} width={[1, 1/2]}>
        {children}
      </Box>
      <Box px={[0, 4]} py={[0]} width={[1, 1/2]}>
        {image}
      </Box>
    </Flex>
  </Box>
);

const TCMPage = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "fotolia-34739627-xl-3.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      tcm1: file(relativePath: { eq: "tcm-1.jpg" }) {
        childImageSharp {
          fixed(width: 600, height: 325) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tcm2: file(relativePath: { eq: "tcm-2.jpg" }) {
        childImageSharp {
          fixed(width: 600, height: 325) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tcm3: file(relativePath: { eq: "tcm-3.jpg" }) {
        childImageSharp {
          fixed(width: 600, height: 325) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tcm4: file(relativePath: { eq: "tcm-4.jpg" }) {
        childImageSharp {
          fixed(width: 600, height: 325) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="TCM" />
      <Hero width={[1, 1/2]} backgroundImage={data.header.childImageSharp.fluid}>
        <Title>TCM</Title>
        <Enumeration items={items} />
      </Hero>
      <Box px={[3, 5]} py={5}>
        <ContentSection id="akupunktur" title="Akupunktur" image={<Img fixed={data.tcm1.childImageSharp.fixed} style={{ width: '100%' }} />}>
          Im Mittelpunkt der Chinesischen Medizin steht die Vorstellung von einer im Körper fließenden Lebensenergie (Qi), deren harmonisches Fließen im Falle von Krankheiten gestört ist. Die Akupunktur reguliert den Qi-Fluss und bringt den Körper wieder in seinen natürlichen, ausgeglichenen Zustand. Dabei werden hauchdünne Nadeln in sog. Meridian- oder Akupunkturpunkte gesetzt. Durch die Reizung dieser Punkten werden Hormonspiegel verändert, so dass vermehrt körpereigene Endorphine gegen Schmerzen ausgeschüttet und die Selbstheilungskräfte des Körpers aktiviert werden.
        </ContentSection>
        <hr />
        <ContentSection id="moxibustion" title="Moxibustion & Schröpfen" image={<Img fixed={data.tcm2.childImageSharp.fixed} style={{ width: '100%' }} />}>
          Zur körperlichen Stimulation werden in Abhängigkeit von Konstitution und Erkrankung des Patienten nicht nur Nadeln, sondern auch Moxakegel oder Zigarren und Schröpfköpfe verwendet. Die Moxibustion ist das Anwärmen von gewissen Punkten mit getrocknetem Beifuß. Bei der Schröpfbehandlung werden die Schröpfgläser durch Feuer von innen erwärmt und auf die Haut gesetzt, so dass ein Unterdruck entsteht. Dadurch wird die Durchblutung gefördert, der Stoffwechsel verbessert sowie Schlacken abtransportiert.
        </ContentSection>
        <hr />
        <ContentSection id="ernaehrungsberatung" title="Ernährungsberatung nach TCM" image={<Img fixed={data.tcm3.childImageSharp.fixed} style={{ width: '100%' }} />}>
          Die chinesische Diätetik ist ein wichtiger Baustein im Gesamtgefüge der Chinesischen Medizin. Sie ermöglicht es, den Organismus in einer Weise zu stärken, dass Krankheit erst gar nicht entsteht. Diese Ernährungslehre richtet sich nach dem Temperatur- und Geschmacksverhalten von Nahrungsmitteln sowie ihrer energetischen Wirkung. Eingebettet in das chinesische Diagnose- und Therapieprinzip lässt sich so für jeden Patienten ein ganz individueller Ernährungsplan erstellen.
        </ContentSection>
        <hr />
        <ContentSection id="diagnostik" title="Diagnostik" image={<Img fixed={data.tcm4.childImageSharp.fixed} style={{ width: '100%' }} />}>
        In der Chinesischen Medizin werden Krankheiten niemals isoliert, sondern immer im Zusammenhang betrachtet. Dabei wird der Mensch in seiner Gesamtheit (Körper, Seele, Geist) und unter Einbeziehung seiner Umwelt und Lebensgewohnheiten betrachtet.
        </ContentSection>
      </Box>
    </Layout>
  )
}

export default TCMPage
